<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <div class="d-flex justify-content-between align-items-center">
                <h4 class="m-0">Organisation View</h4>
                <div>
                  <b-button variant="outline-red" squared @click="openDeleteModal" size="sm">Delete</b-button>             
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row class="h-100" v-if="state === 'loading'" align-h="center">
            <b-spinner label="Loading..."></b-spinner>
          </b-row>
          
         <div v-if="state === 'show'">
           <b-row>
             <b-col cols="4">
               <label>Name <span class="text-red">*</span></label>
               <b-form-input v-model="orgData.name" 
                             @blur="orgData.name.$touch()"></b-form-input>             
             </b-col>
             <b-col cols="4">
               <label>Logo <span class="text-red">*</span></label>
               <b-form-file v-model="file">
                 <template slot="file-name" slot-scope="{ names }">
                   <b-badge variant="dark">{{ names[0] }}</b-badge>
                   <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                     + {{ names.length - 1 }} More files
                   </b-badge>
                 </template>
               </b-form-file>
             </b-col>
           </b-row>
          <b-row v-if="orgData.logo != '' && orgData.logo != null">
            <b-col cols="4">
               <label>Logo on file</label><br/>
               <img width="65" height="65" v-bind:src="url + orgData.logo"/>
             </b-col> 
          </b-row>
           <hr class="mx-3">
           <b-row>
             <b-col>
               <div class="d-flex justify-content-end">
                 <div>
                   <b-button variant="outline-red" squared @click="goBackToSearch" class="ml-2">Cancel</b-button>
                 </div>
                 <div>
                   <b-button variant="primary" squared @click="updateOrganisation()" class="ml-2" >Save</b-button>
                 </div>
               </div>
             </b-col>
           </b-row>
         </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="userModal" hide-footer hide-header-close title="Delete User">

      <b-row>
        <b-col cols="12">
          <label class="text-center">Are you sure you want to delete <span class="font-weight-bold text-red text-center">{{this.orgData.name}}</span>?</label>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-center mt-3">
          <b-button variant="outline-red" @click="hideDeleteModal" squared class="mr-2" >Cancel</b-button>
          <b-button variant="red" @click="removeOrganisation" squared >Delete</b-button>
        </b-col>
      </b-row>
      <div class="d-block"></div>
    </b-modal>
  
  </div>
</template>
<style >
.primary{
background-color: #ffcb14;
color: #222222;
}
.primary:hover{
    color: #fff;
}
.page-item.active .page-link {
    z-index: 3;
    color: #222222;
    background-color: #ffcb14;
    border-color: #ffcb14;
}
</style>
<script>
import {mapActions, mapMutations, mapState} from "vuex";
import {required} from "vuelidate/lib/validators";
  import api from "../../../api";
import axios from "axios";

export default {
  file: null,
  name: "orgView",
  data: () => ({
    state: 'show',
    orgData: {
      Id: 0,
      name: null,
      logo: null,
    },
    url: ""
  }),
  created() {
    this.url = api.Url;
    this.setBreadcrumb([
      {
        text: 'Organisations'
      },
      {
        text: 'Details'
      },
    ]);
    const s = (data) => {
        this.orgData = data;


        console.log(data);
        this.state = 'show';
      };

      const e = (msg) => {
        console.log(msg);
      };
      this.orgData.id = localStorage.getItem("view_organisation_id");
      api.getOrganisationID(localStorage.getItem("view_organisation_id"), s, e);


  },
  methods: {
    ...mapActions(["getUserDetail", "updateUser", "deleteUser", "getUserRoles", "createNewPassword"]),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    
  updateOrganisation() {

    if(this.file == null) {
      const s = (data) => {
        this.$router.push({
          path: '/admin/ManageOrganisations/SearchOrganisation'
        })
        console.log(data);
      };

      const e = (msg) => {
        console.log(msg);
      };

      console.log(this.orgData);
      api.UpdateOrganisation(this.orgData, s, e);
      return;
    }

     const formData = new FormData();
      formData.append("file", this.file);
      console.log(this.file)
      axios.post(api.Url + "/Cdn", formData, {
        headers: {
          Authorization : `Bearer ${localStorage.getItem("api_Key")}`,
          'Content-Type': 'multipart/form-data'
        }
      }).then((resp) => {


        console.log(this.file)
        this.orgData.logo = "/Cdn/Get/" + resp.data + "/" + this.file.name;
        console.log(this.orgData.logo)
        const s = (data) => {
          this.$router.push({
            path: '/admin/ManageOrganisations/SearchOrganisation'
          })
          console.log(data);
        };

        const e = (msg) => {
          console.log(msg);
        };

        console.log(this.orgData);
        api.UpdateOrganisation(this.orgData, s, e)

      }).catch((error) => {
        console.error(error);
      });
    },

      removeOrganisation() {   
        var s = (data) => {
          console.log(data)
        };
        var e = (error) => {
          console.log(error)
        };

        api.DeleteOrganisation(this.orgData, s, e)
        this.hideDeleteModal();
        // window.location = '/admin/ManageOrganisations/SearchOrganisation';
        this.$router.push({path: '/admin/ManageOrganisations/SearchOrganisation'})
      },
    
    goBackToSearch(){
      this.$router.push({path: '/admin/ManageOrganisations/SearchOrganisation'})
    },
    
    openDeleteModal() {
      this.$bvModal.show('userModal')
    },
    

    hideDeleteModal() {
      this.$bvModal.hide('userModal')
    },

  },
  computed: {
    ...mapState([
        'selectedUser'
    ])
  },
  validations: {
    selectedUser: {
      name: {required},
    },
  },
}
</script>

<style scoped>

</style>